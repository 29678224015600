import React from 'react';
import { graphql } from 'gatsby';
import { Col, Row, Text } from '@fast-ai/ui-components';
import Heading from '@fast-ai/ui-components/Heading';

import MdxPage from '../layout/MdxPage';
import { Contacts, Section } from '../ui';
import { RichFormattedMessage } from '../intl';
import m from '../intl/messages/pageBlogArticle';
import { genLocalizedTopicLink } from '../ui/BlogTopicLink';

const getPageInfo = result => {
	if (!result) {
		return;
	}
	const {
		frontmatter: { title },
		fields: { slug },
	} = result;

	return { title, slug };
};

const BlogPage = ({
	data: { prev, next, mdx },
	pageContext: { language: currentLanguage },
	...rest
}) => {
	const prevPage = getPageInfo(prev);
	const nextPage = getPageInfo(next);

	const after = (
		<Section variant="stripesSecondary">
			<Row sx={{ flexWrap: 'wrap' }}>
				<Col span={12}>
					<Heading as="h2" sx={{ pt: [0], mb: [0, 0, 0] }}>
						<RichFormattedMessage {...m.footerHeading} />
					</Heading>

					<Text as="p" variant="subHeading2">
						<RichFormattedMessage {...m.footerText} />
					</Text>
				</Col>
			</Row>
			<Contacts />
		</Section>
	);

	return (
		<MdxPage
			showShareButtons
			after={after}
			prevPage={prevPage}
			nextPage={nextPage}
			{...rest}
			mdx={{
				...mdx,
				frontmatter: {
					...mdx.frontmatter,
					topics: mdx.frontmatter.topics.map(topicData =>
						genLocalizedTopicLink({ currentLanguage, topicData })
					),
				},
			}}
		/>
	);
};
export default BlogPage;

export const pageQuery = graphql`
	query MdxBlogPageQuery($id: String!, $prev: String, $next: String) {
		prev: mdx(id: { eq: $prev }) {
			fields {
				slug
			}
			frontmatter {
				title
			}
		}
		next: mdx(id: { eq: $next }) {
			fields {
				slug
			}
			frontmatter {
				title
			}
		}
		mdx(id: { eq: $id }) {
			frontmatter {
				title
				subtitle
				description
				hashtags
				topics {
					name
					slug
					description
					lang
					alternates {
						name
						slug
						description
						lang
					}
				}
				author {
					fullName
					image {
						childImageSharp {
							gatsbyImageData(layout: CONSTRAINED, width: 32, height: 32)
						}
					}
				}
				thumbnail {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
				thumbnailSeo: thumbnail {
					childImageSharp {
						fixed(width: 1200) {
							src
							width
							height
						}
					}
				}
				published
			}
			timeToRead
			id
			body
			tableOfContents
		}
	}
`;
