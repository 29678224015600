import React, { Fragment, Suspense } from 'react';
import PropTypes from 'prop-types';

const isSSR = typeof window === 'undefined';

const BrowserOnly = ({ lazyComponent: LazyComponent, ...rest }) =>
	isSSR ? (
		<Fragment />
	) : (
		<Suspense fallback={<div />}>
			<LazyComponent {...rest} />
		</Suspense>
	);

BrowserOnly.propTypes = {
	lazyComponent: PropTypes.elementType,
};

export default BrowserOnly;
