import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@fast-ai/ui-components';

const ellipsisStyle = {
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
};

const Ellipsis = forwardRef(
	({ children, renderText: RenderText = Text, sx, ...rest }, ref) => {
		const childrenProps = {
			sx: {
				...ellipsisStyle,
				...sx,
			},
			ref,
			...rest,
		};

		return typeof children === 'function' ? (
			children(childrenProps)
		) : (
			<RenderText {...childrenProps}>{children}</RenderText>
		);
	}
);

Ellipsis.propTypes = {
	children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.node])
		.isRequired,
	renderText: PropTypes.elementType,
};

Ellipsis.displayName = 'Ellipsis';

export default Ellipsis;
