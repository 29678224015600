import { defineMessages } from 'gatsby-theme-fast-ai-sidebar';

export default defineMessages({
	footerHeading: {
		id: 'page.blogArticle.footerHeading',
		defaultMessage: 'Did you have <strong>fun</strong> reading it?',
	},
	footerText: {
		id: 'page.blogArticle.footerText',
		defaultMessage: "Let's talk about it more!",
	},
});
