import React, { lazy } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-theme-fast-ai-sidebar';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Col, Row } from '@fast-ai/ui-components';
import MdxProvider from 'gatsby-theme-fast-ai-sidebar/src/components/MdxProvider';
import TableOfContents from 'gatsby-theme-fast-ai-sidebar/src/components/TableOfContents';
import Box from '@fast-ai/ui-components/Box';
import BrowserOnly from '@fast-ai/ui/BrowserOnly';
import KeyboardArrowLeft from '@fast-ai/dashboard-icons/src/KeyboardArrowLeft';
import KeyboardArrowRight from '@fast-ai/dashboard-icons/custom/KeyboardArrowRight';
import Ellipsis from '@fast-ai/ui/Ellipsis';

import { Section, Seo } from '../ui';
import ArticlePreview from '../ui/ArticlePreview';

import DefaultLayout from './DefaultLayout';

const ShareButtons = lazy(() => import('../ui/ShareButtons'));

const styles = {
	navLink: { display: 'flex', alignItems: 'center', px: 0 },
};

const MdxPage = ({
	location,
	children,
	mdx,
	nextPage,
	prevPage,
	disableTableOfContents = false,
	tableOfContentsDepth = 3,
	after,
	showShareButtons,
	...rest
}) => {
	const {
		description,
		title,
		subtitle,
		author: { fullName: authorFullName, image: authorImage } = {},
		thumbnail,
		thumbnailSeo,
		published,
		topics,
		hashtags = ['zoe_ai', 'deep_recommendation'],
	} = mdx.frontmatter;
	const { timeToRead } = mdx;

	const hideMeta = timeToRead == null && authorFullName == null;
	return (
		<MdxProvider>
			<DefaultLayout location={location} fluidLayout {...rest}>
				<Seo title={title} description={description} ogImage={thumbnailSeo} />

				<Section>
					<ArticlePreview
						headingLevel={1}
						hidePerex
						hideMeta={hideMeta}
						layout="vertical-middle"
						size="large"
						title={title}
						subtitle={subtitle}
						author={authorFullName}
						authorImage={authorImage}
						published={published}
						timeToRead={timeToRead}
						topics={topics}
						thumbnail={thumbnail}
					/>
					<Row flexDirection={{ _: 'column', lg: 'row-reverse' }}>
						{!disableTableOfContents && (
							<Col span={{ _: 12, lg: 3 }}>
								<TableOfContents
									maxDepth={tableOfContentsDepth}
									sx={{
										textAlign: 'left',
										position: ['static', 'static', 'static', 'sticky'],
										top: 112,
										right: 0,
									}}
									location={location}
									items={mdx.tableOfContents.items}
								/>
							</Col>
						)}
						<Col
							span={{ _: 12, lg: disableTableOfContents ? 12 : 9 }}
							sx={{ textAlign: 'left' }}
						>
							<MDXRenderer>{mdx.body}</MDXRenderer>
							{children}
						</Col>
					</Row>
					{nextPage || prevPage ? (
						<Row>
							<Col
								span={12}
								sx={{
									display: 'flex',
									flexWrap: 'wrap',
									justifyContent: 'space-between',
									alignItems: 'center',
								}}
							>
								{prevPage ? (
									<Ellipsis
										sx={styles.navLink}
										title={prevPage.title}
										variant="nav"
										to={prevPage.slug}
									>
										{props => (
											<Link {...props}>
												<KeyboardArrowLeft />
												{prevPage.title}
											</Link>
										)}
									</Ellipsis>
								) : (
									<Box />
								)}
								{nextPage ? (
									<Ellipsis
										sx={styles.navLink}
										title={nextPage.title}
										variant="nav"
										to={nextPage.slug}
									>
										{props => (
											<Link {...props}>
												{nextPage.title}
												<KeyboardArrowRight />
											</Link>
										)}
									</Ellipsis>
								) : (
									<Box />
								)}
							</Col>
						</Row>
					) : null}
					{showShareButtons && (
						<Row sx={{ borderTop: t => t.borders.normal, pt: 4 }}>
							<Col span={12}>
								<BrowserOnly
									lazyComponent={ShareButtons}
									url={location.origin + location.pathname}
									title={title}
									subtitle={subtitle}
									hashtags={hashtags}
									size={48}
								/>
							</Col>
						</Row>
					)}
				</Section>

				{after}
			</DefaultLayout>
		</MdxProvider>
	);
};
MdxPage.propTypes = {
	children: PropTypes.node,
	location: PropTypes.object,
	mdx: PropTypes.object,
};

export default MdxPage;
