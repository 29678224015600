/* eslint-disable max-len */
import React, { forwardRef } from 'react';

import SvgIcon from './Icon';

const KeyboardArrowLeft = forwardRef(({ ...rest }, ref) => (
	<SvgIcon viewBox="0 0 24 24" {...rest} ref={ref}>
		<path d="M15.41 16.59L10.83 12L15.41 7.41L14 6L8 12L14 18L15.41 16.59Z" />
	</SvgIcon>
));

KeyboardArrowLeft.displayName = 'KeyboardArrowLeft';

export default KeyboardArrowLeft;
